<template>
    <div class="page bg-white" id="reorganize">
        <el-row :gutter="20">
            <el-col :span="6">
                <div class="lump">
                    <div class="catalogueBox">
                        <el-table
                                :data="catalogueList"
                                ref="catalogueTable"
                                size="small"
                                height="calc(100vh - 188px)"
                                class="table" highlight-current-row
                                @current-change="handleCurrentChange">
                            <el-table-column prop="label" label="详情页" show-overflow-tooltip/>
                        </el-table>
                    </div>
                </div>
            </el-col>
            <el-col :span="18">
                <div class="lump">
                    <el-row class="headBox">
                        <el-col :span="10">
                            <div class="flex_l_c">
                                <el-input class="flex1 m_r1" maxlength="66" clearable v-model="fileSrh" placeholder="请输入著录项名" size="small"></el-input>
                                <el-button icon="el-icon-search" type="primary" size="small" @click="searchCatalogue">查询</el-button>
                                <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
                            </div>
                        </el-col>
                        <el-col :span="14" class="text_right">
                            <el-button icon="el-icon-plus" size="small" :disabled="exhibitionId==''"  type="primary" @click="view('add', exhibitionId)">新建</el-button>
                            <el-button type="danger" size="small" icon="el-icon-delete" @click="del()" :disabled="dataListSelections.length <= 0" plain>删除</el-button>
                        </el-col>
                    </el-row>
                    <div class="catalogueBox">
                        <el-table
                                :data="fileList"
                                size="small"
                                @selection-change="selectionChangeHandle"
                                v-loading="loading"
                                height="calc(100vh - 290px)"
                                class="table">
                            <el-table-column type="selection" width="50"/>
                            <el-table-column prop="sortnumber" label="排序" width="50"></el-table-column>
<!--                            <el-table-column prop="recordCode" label="著录项编号" show-overflow-tooltip/>-->
                            <el-table-column prop="recordName" label="著录项名" show-overflow-tooltip/>
<!--                            <el-table-column prop="fieldEnname" label="著录项字段值" show-overflow-tooltip/>-->
                            <el-table-column prop="detailsShowType" label="著录项类型" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    {{ $dictUtils.getDictLabel("Item_description_type", scope.row.detailsShowType, '-') }}
                                </template>
                            </el-table-column>
                            <el-table-column  prop="publishState" label="发布状态" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <el-tag :type="scope.row.publishState == 0?'success':'danger'" >{{scope.row.publishState == 0 ? '已发布' : '未发布'}}</el-tag>
                                </template>
                            </el-table-column>
<!--                            <el-table-column sortable prop="onShow" label="是否显示" show-overflow-tooltip>-->
<!--                                <template slot-scope="scope">-->
<!--                                    <el-tag :type="scope.row.onShow == 0?'success':'danger'" >{{scope.row.onShow == 0 ? '是' : '否'}}</el-tag>-->
<!--                                </template>-->
<!--                            </el-table-column>-->
                            <el-table-column fixed="right" label="操作" width="100">
                                <template slot-scope="scope">
                                    <el-button size="mini" type="primary" plain @click="view('edit', scope.row)">编辑</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination
                                @size-change="sizeChangeHandle"
                                @current-change="currentChangeHandle"
                                :current-page="pageNo"
                                :page-sizes="[10, 20, 50, 100]"
                                :page-size="pageSize"
                                :total="total"
                                background
                                layout="total, prev, pager, next, sizes">
                        </el-pagination>
                    </div>
                </div>
            </el-col>
        </el-row>
        <CreateTemplate ref="createTemplate" @refreshDataList="getFileList"></CreateTemplate>
    </div>
</template>

<script>
    import CreateTemplate from './createTemplate' // 创建模板弹窗

    export default {
        components: {CreateTemplate},
        data() {
            return {
                visibleLog: false,
                catalogueSrh: '',
                catalogueList: this.$dictUtils.getDictList('details_type'),
                pageNo: 1,
                pageSize: 10,
                total: 0,
                fileSrh: '',
                fileList: [],
                dataListSelections: [],
                loading: false,
                exhibitionId: "",
            }
        },
        methods: {
            // 新建、编辑
            view(method, row) {
                this.$refs.createTemplate.init(method, row)
            },
            // 表格多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 重置
            resetSearch() {
                this.fileSrh = '';
                this.pageNo = 1;
                this.getFileList()
            },
            // 点击展览行
            handleCurrentChange(val) {
                this.$refs.catalogueTable.setCurrentRow(val);
                this.exhibitionId = val.value
                this.searchCatalogue()
            },
            //搜索
            searchCatalogue() {
                this.pageNo = 1;
                this.getFileList();
            },
            // 查询目录
            getFileList() {
                if(this.exhibitionId!=""){
                    this.loading = true
                    this.$axios(this.api.zlzs.originalDescriptionList, {
                        'flag': this.exhibitionId,
                        "onDetails":0,
                        "recordName": this.fileSrh,
                        'pageNo': this.pageNo,
                        'pageSize': this.pageSize,
                    }, 'get').then(data => {
                        if (data && data.status) {
                            this.fileList = data.data.records
                            this.total = parseInt(data.data.total)
                            this.loading = false
                        }
                    })
                }
            },
            // 删除、批量删除
            del(id) {
                // 批量删除多个id用逗号隔开
                let ids = id || this.dataListSelections.map(item => {
                    return item.id
                }).join(',')
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.zlzs.originalDescriptionRemoveById, {
                        'ids': ids,
                    }, 'delete').then(data => {
                        if(data && data.status){
                            this.$message.success(data.msg)
                            if(this.dataListSelections.length==this.fileList.length){
                                this.pageNo=1
                            }
                            this.getFileList()
                        }else{
                            this.$message.error(data.msg)
                        }
                    })
                })
            },
            // 展览每页数
            sizeChangeHandle(val) {
                this.pageSize = val;
                this.pageNo = 1;
                this.getFileList();
            },
            // 展览当前页
            currentChangeHandle(val) {
                this.pageNo = val;
                this.getFileList();
            },
        }
    }
</script>

<style scoped>

</style>
